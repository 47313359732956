import PropTypes from 'prop-types';
import React from 'react';

import useFilters from '~/utils/use-filters';
import Header from '~/components/header';
import Layout from '~/components/layout';
import Section from '~/components/section';

export default function Landing({ refills: allRefills, ...props }) {
  const [
    refills,
    [
      [ type, setType ],
      [ color, setColor ],
      [ size, setSize ],
    ],
    [ allTypes, allColors, allSizes ],
  ] = useFilters(allRefills, [ 'type', 'color', 'size' ]);

  return (
    <Layout title="Pens By Yetti" {...props}>
      <Section title="Inks and Refills" id="main" className="wrapper style2 right-sidebar">
        <div className="row gtr-150">
          <div className="col-12">
            <div id="content">
              <article className="box post">
                <Header title="Need an Ink or Refill?" className="style1">
                  Here are links to the highest quality refills.
                </Header>
                <div className="container">
                  <div className="row aln-center highlights">
                    <div className="col-12 center">
                      <form>
                        <select className="inline" title="Filter by Type" value={JSON.stringify(type)} onChange={e => setType(JSON.parse(e.target.value))}>
                          <option value={JSON.stringify('')}>- Show All Types -</option>
                          {allTypes.map(type => (
                            <option key={type} value={JSON.stringify(type)}>{type}</option>
                          ))}
                        </select>

                        <select className="inline" title="Filter by Color" value={JSON.stringify(color)} onChange={e => setColor(JSON.parse(e.target.value))}>
                          <option value={JSON.stringify('')}>- Show All Colors -</option>
                          {allColors.map(color => (
                            <option key={color} value={JSON.stringify(color)}>{color}</option>
                          ))}
                        </select>

                        <select className="inline" title="Filter by Size" value={JSON.stringify(size)} onChange={e => setSize(JSON.parse(e.target.value))}>
                          <option value={JSON.stringify('')}>- Show All Sizes -</option>
                          {allSizes.map(size => (
                            <option key={size} value={JSON.stringify(size)}>{size}</option>
                          ))}
                        </select>
                      </form>
                    </div>
                    <div className="col-6 col-12-medium">
                      <ul>
                        {refills.map(({ id, label, type, color, size, link }) => (
                          <li key={id}>
                            <a href={link} target="_blank" rel="noopener noreferrer">
                              {[ label, type, color, size ].filter(Boolean).join(' - ')}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
}

Landing.propTypes = {
  refills: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    size: PropTypes.string,
    link: PropTypes.string.isRequired,
  })).isRequired,
};
