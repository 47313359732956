import PropTypes from 'prop-types';
import CustomPropTypes from 'custom-prop-types';
import React from 'react';
import { graphql } from 'gatsby';

import Refills from '~/scenes/refills';

export default function RefillsPage({ location: { pathname }, data: { refills } }) {
  refills = CustomPropTypes.transformAirtable(refills);

  return (
    <Refills path={pathname} refills={refills} />
  );
}

RefillsPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    refills: CustomPropTypes.airtable({
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      size: PropTypes.string,
      link: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query getRefills {
    refills: allAirtable(filter: { table: { eq: "refills" } }) {
      nodes {
        id
        data {
          label
          type
          color
          size
          link
        }
      }
    }
  }
`;
